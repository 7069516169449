import logoLight from "@/images/logo_s.png";
import logoDark from "@/images/logo2_s.png";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};

export const NavLinks = [
  {
    name: "PRINCIPAL",
    url: "/",    
  },
  {
    name: "Sobre",
    url: "/about",
  },
  {
    name: "Serviços",
    url: "/service",    
  },
  {
    name: "Portfolio",
    url: "/portfolio",
  },  
  {
    name: "Contato",
    url: "/contact",
  },
];

import sliderOne01 from "@/images/slider/6.jpg";
import sliderOne02 from "@/images/slider/1.jpg";

export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: "BEM VINDO AO MEU PORTFOLIO",
    title: "FABRÍCIO OLIVEIRA SEVENHEADS",
    button: {
      label: "MEUS PROJETOS",
      url: "/portfolio",
    },
  },
  {
    image: sliderOne02,
    subTitle: "BEM VINDO AO MEU PORTFOLIO",
    title: "FABRÍCIO OLIVEIRA SEVENHEADS",
    button: {
      label: "MEUS PROJETOS",
      url: "/portfolio",
    },
  },
];

export const ContactInfosList = [
  {
    title: "São Paulo",
    infos: [
      {
        name: "Jundiaí",
      },
      {
        name: "contato@sevenheads.com.br",
      },
      {
        name: "11 9 4922-7243",
      },
    ],
  }
];

export const ContactFormTitle = {
  subTitle: "Entre em contato",
  title: "Me envie seu problema",  
  description: "Deixe sua mensagem e conte-me em que posso ajudar. \n Posso garantir que irei me esforçar para auxiliá-lo da melhor forma possível."
};

import portfolio01 from "@/images/portfolio/1p_2.jpg";
import portfolio02 from "@/images/portfolio/2p_2.jpg";
import portfolio03 from "@/images/portfolio/3p_2.jpg";
import portfolio04 from "@/images/portfolio/4p_2_2.jpg";
import portfolio05 from "@/images/portfolio/5p_1.png";
import portfolio06 from "@/images/portfolio/6p_2.png";

export const PortfolioData = [
  {
    title: "Sistema Web",
    categories: [],
    image: portfolio01,
    url: "https://system.sevenheads.com.br/",
  },
  {
    title: "API Sistema",
    categories: ["BackEnd"],
    image: portfolio02,
    url: "https://api.sevenheads.com.br/swagger-ui/index.html",
  },
  {
    title: "SevenHeads",
    categories: ["Landing Page"],
    image: portfolio03,
    url: "https://www.sevenheads.com.br/",
  },  
  {
    title: "Portfolio",
    categories: [],
    image: portfolio04,    
  },
  {
    title: "Positron Stoneridge",
    categories: ["Experiência Profissional"],
    image: portfolio05,
    url: "https://www.positron.com.br/",    
  },
  {
    title: "SIQ",
    categories: ["Experiência Profissional"],
    image: portfolio06,
    url: "https://www.siq.com.br/",
  },
];

export const ServicePostData = [
  {
    title: "Sistemas Web",
    text:
      "Serviços de desenvolvimento web personalizados, criação de sites, aplicativos e sistemas, focado em usabilidade, design moderno e desempenho.",
    iconName: "sh-settings",    
  },
  {
    title: "Serviços",
    text:
      "Soluções digitais, otimização de sistemas e processos, melhorias de design e experiência do usuário, criação e suporte em microsserviços.",
    iconName: "sh-transfer",
  },  
];

export const ServiceHomeThreeData = {
  subTitle: "Serviços em que posso ajudar a desenvolver",
  title: "Serviços",
  text:
    "Conte com nossa experiência e expertise para criar soluções digitais personalizadas que impulsionem seu negócio. \n Desenvolvimento web, design gráfico, marketing digital e muito mais - tudo em um só lugar.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "Conte comigo e minha equipe para criar soluções personalizadas e de qualidade para o seu projeto.",
    subTitle: "Serviços em que posso ajudar a desenvolver",
    text:
      "Conte com nossa experiência e expertise para criar soluções digitais personalizadas que impulsionem seu negócio. \n Desenvolvimento web, design gráfico, marketing digital e muito mais - tudo em um só lugar.",
  },
  posts: [
    {
      title: "Desenvolvimento de Site",
      iconName: "sh-web-design",      
    },
    {
      title: "Design e Gráficos",
      iconName: "sh-computer-graphic",
    },
    {
      title: "Marketing Digital",
      iconName: "sh-development-1",
    },
    {
      title: "SEo & Criação de Conteúdo",
      iconName: "sh-development",
    },
    {
      title: "Desenvolvimento de Aplicativo",
      iconName: "sh-app-development",
    },
  ],
};

import ClientCarousel01 from "@/images/client/1_1.png";
import ClientCarousel02 from "@/images/client/1_2.png";
import ClientCarousel03 from "@/images/client/1_3.png";
import ClientCarousel04 from "@/images/client/1_4.png";
import ClientCarousel05 from "@/images/client/1_5.png";
import ClientCarousel06 from "@/images/client/1_6.png";
import ClientCarousel07 from "@/images/client/1_7.png";
import ClientCarousel08 from "@/images/client/1_8.png";
import ClientCarousel09 from "@/images/client/1_9.png";
import ClientCarousel010 from "@/images/client/1_10.png";
import ClientCarousel011 from "@/images/client/1_11.png";
import ClientCarousel012 from "@/images/client/1_12.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "Serviços, Componentes e Softwares",
    subTitle: "Tecnologias",
    text:
      "Tenho experiência e habilidade no uso de diversas ferramentas para resolver problemas e desenvolver novos produtos. \n Aqui estão algumas delas.",
  },
  items: [
    {
      /*url: "#",*/
      image: ClientCarousel01,
    },
    {
      /*url: "#",*/
      image: ClientCarousel02,
    },
    {
      /*url: "#",*/
      image: ClientCarousel03,
    },
    {
      /*url: "#",*/
      image: ClientCarousel04,
    },
    {
      /*url: "#",*/
      image: ClientCarousel05,
    },
    {
      /*url: "#",*/
      image: ClientCarousel06,
    },
    {
      /*url: "#",*/
      image: ClientCarousel07,
    },
    {
      /*url: "#",*/
      image: ClientCarousel08,
    },
    {
      /*url: "#",*/
      image: ClientCarousel09,
    },
    {
      /*url: "#",*/
      image: ClientCarousel010,
    },
    {
      /*url: "#",*/
      image: ClientCarousel011,
    },
    {
      /*url: "#",*/
      image: ClientCarousel012,
    },
  ],
};

export const ParallaxOneData = {
  iconName: "sh-team",
  title: "GRANDES COISAS NOS NEGÓCIOS NUNCA SÃO FEITAS POR UMA PESSOA.",
  specialText: "SÃO FEITAS POR UMA EQUIPE DE PESSOAS.",
  text:
    "",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "Showcase",
    subTitle: "Portfolio",
    text:
      "Confira meus projetos showcase, exemplos de excelência e inovação em ação.",
  },
};

import aboutTwo01 from "@/images/home_1/matrix.gif";
import aboutTwo02 from "@/images/home_1/2s.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "Com foco implacável, alcanço resultados.",
    subTitle: "Sobre mim",
    text:
      "Sou apaixonado por desenvolvimento de software. \n Com expertise em Java, Spring Boot, JSF, PrimeFaces e outras tecnologias relacionadas, crio soluções robustas e eficientes. Minha determinação impulsiona cada projeto, garantindo resultados excepcionais.",
  },
  button: {
    label: "Mais sobre mim",
    url: "/about",
  },
  gallery: [aboutTwo01, aboutTwo02],
};